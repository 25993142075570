import { useEffect, useState, useRef } from 'react';

export const useTimer = (callbackWhenTimerEnd = () => {}, time = 5) => {
  const [counter, setCounter] = useState(time);
  const counterRef = useRef(null);

  useEffect(() => {
    counterRef.current = setInterval(() => {
      setCounter((c) => c - 1);
    }, 1000);

    return () => {
      clearTimeout(counterRef.current);
      setCounter(null);
    };
  }, []);

  useEffect(() => {
    if (counter <= 0) {
      clearTimeout(counterRef.current);
      callbackWhenTimerEnd();
    }
  }, [counter, callbackWhenTimerEnd]);

  return [counter];
};
