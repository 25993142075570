import * as S from './styles';

const defaultProps = {
  block: false,
  inverse: false,
  fontSizeIndex: null,
  loadingMessage: '',
};

export const Spinner = ({ block, inverse, fontSizeIndex, loadingMessage, ...restProps } = defaultProps) => (
  <S.Spinner
    aria-live="polite"
    role="status"
    block={block}
    inverse={inverse}
    fontSizeIndex={fontSizeIndex}
    {...restProps}
  />
);

Spinner.defaultProps = defaultProps;
