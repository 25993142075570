import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { em } from 'polished';

export const Spacer = styled.hr`
  padding-top: ${(p) => em(p.theme.spacing[4])};
  padding-top: ${(p) => em(p.theme.spacing[p.isLarge && 6])};
  padding-top: ${(p) => em(p.theme.spacing[p.isSmall && 2])};

  border: none;
  height: 0;
  font-size: 0.5rem;
  margin: 0;
  display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'block')};

  ${up('tablet')} {
    font-size: 0.75rem;
    display: block;
    display: ${({ hideOnDesktop }) => hideOnDesktop && 'none'};
  }

  ${up('desktop')} {
    font-size: 1rem;
  }
`;
