import * as S from './styles';

const defaultProps = {
  large: false,
  hideOnMobile: false,
};

export const Spacer = ({ large, hideOnMobile, hideOnDesktop, small, ...restProps } = defaultProps) => {
  return (
    <S.Spacer
      isLarge={large}
      hideOnMobile={hideOnMobile}
      hideOnDesktop={hideOnDesktop}
      isSmall={small}
      role="none"
      {...restProps}
    />
  );
};

Spacer.defaultProps = defaultProps;
