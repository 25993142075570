import styled, { css } from 'styled-components';
import { em, rem } from 'polished';
import { up } from 'styled-breakpoints';
import { classNamesOfLayout } from 'src/enums/classNamesOfLayout';

const primaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultHeadingVariant('primary', props)};
  width: 100%;
  margin: 0 0 0.5em;
`;

const secondaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultHeadingVariant('secondary', props)};
  width: 100%;
  margin: 0 0 0.5em;

  & > span {
    small {
      font-family: ${(p) => p.theme.heading.secondary.small.fontFamily};
      font-size: 0.625em;
      display: block;
      margin-top: 0.125em;
    }
  }
`;

const tertiaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultHeadingVariant('tertiary', props)};
  width: 100%;
  margin: 0 0 0.5em;
`;

const quinaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultHeadingVariant('quinary', props)};
  white-space: nowrap;
  position: relative;
  display: inline-block;
  margin-bottom: 0.5em;

  &:before {
    content: '';
    position: absolute;
    bottom: -0.25em;
    left: 0;
    height: ${em(2)};
    width: 100%;
    transform: translate3d(0%, 0, 0);
    background: ${props.theme.heading.quinary.decogBg};
    ${props.theme.helpers.transition(['background', 'transform'])};
  }
`;
const senaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultHeadingVariant('senary', props)};
  width: 100%;
  margin: 0 0 0.5em;
`;

const septenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultHeadingVariant('septenary', props)};
  width: 100%;
  margin: 0 0 0.5em;

  background-color: ${({ theme }) => theme.heading.septenary.smallScreenColors.background};
  color: ${({ theme }) => theme.heading.septenary.smallScreenColors.text};
  padding: 0.25em 2em;

  ${up('tablet')} {
    background-color: unset;
    color: ${({ theme }) => theme.heading.septenary.color};
    padding: unset;
  }
`;

const createUnderlineVariantStyles = (props, variantName) => css`
  ${props.theme.helpers.createDefaultHeadingVariant(variantName, props)};
  position: relative;
  text-align: center;
  margin-bottom: ${props.theme.heading.underlineVariant.bottomMargin};

  &:before {
    content: '';
    background: ${props.theme.heading.quaternary.decorBg};
    position: absolute;
    height: ${rem(1)};
    width: 100%;
    top: 50%;
    left: 0;
  }

  & > span {
    background: ${props.theme.heading.quaternary.decorFg};
    padding: 0 0.5em;
    position: relative;

    small {
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 0.25em;
      font-size: 0.5em;
      white-space: nowrap;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const quaternaryVariantStyles = (props) => css`
  ${createUnderlineVariantStyles(props, 'quaternary')}

  .${classNamesOfLayout.LEFT_COLUMN} > * > &,
  .${classNamesOfLayout.LEFT_COLUMN} > * > * > &,
  .${classNamesOfLayout.RIGHT_COLUMN} > * > &,
  .${classNamesOfLayout.RIGHT_COLUMN} > * > * > & {
    ${props.theme.helpers.fontFamilyWithFallback(props.theme.typography.type.secondary)}
  }
`;

const nonaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultHeadingVariant('nonary', props)};

  width: 100%;
  margin: 0 0 0.5em;
`;

const denaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultHeadingVariant('denary', props)};
  width: 100%;
  margin: 0 0 0.5em;
`;

const duodenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultHeadingVariant('duodenary', props)};
  color: ${({ theme }) => theme.heading.duodenary.color};
  position: relative;

  span {
    padding: 0.3em 0.6em 0.3em 0;
    display: inline-block;
    background-color: ${({ theme }) => theme.heading.duodenary.bg};
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    left: 0;
    height: 1px;
    top: 50%;
    background-color: ${({ theme }) => theme.heading.duodenary.lineBg};
    z-index: -1;
  }

  width: 100%;
  margin: 0;
`;

export const Heading = styled.h2`
  font-size: inherit;
  line-height: inherit;
  ${(p) => p.variant === 'primary' && primaryVariantStyles(p)};
  ${(p) => p.variant === 'secondary' && secondaryVariantStyles(p)};
  ${(p) => p.variant === 'tertiary' && tertiaryVariantStyles(p)};
  ${(p) => p.variant === 'quaternary' && quaternaryVariantStyles(p)};
  ${(p) => p.variant === 'quinary' && quinaryVariantStyles(p)};
  ${(p) => p.variant === 'senary' && senaryVariantStyles(p)};
  ${(p) => p.variant === 'septenary' && septenaryVariantStyles(p)};
  ${(p) => p.variant === 'octonary' && createUnderlineVariantStyles(p, 'octonary')};
  ${(p) => p.variant === 'nonary' && nonaryVariantStyles(p)};
  ${(p) => p.variant === 'denary' && denaryVariantStyles(p)};
  ${(p) => p.variant === 'duodenary' && duodenaryVariantStyles(p)};

  ${({ fontStyle, theme }) =>
    theme.typography.type[`${fontStyle}`] &&
    theme.helpers.fontFamilyWithFallback(theme.typography.type[`${fontStyle}`])};
`;
