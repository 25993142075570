import { em, rem, transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { uiComponentVariant } from 'src/enums/uiComponentVariant';

const focusAndHoverEffect = (busy, theme, buttonVariant) =>
  css`
    background: ${!busy && theme.button[buttonVariant].bg.hover};
  `;

const invertFocusAndHoverEffect = (theme, buttonVariant) => css`
  color: ${theme.button[buttonVariant].color.default};
  background: ${theme.button[buttonVariant].bg.default};
  box-shadow: 0 0 0 ${rem(1)} ${theme.button[buttonVariant].bg.hover} inset;
`;

const prepareButtonVariantStyles = (
  buttonVariant = 'primary',
  { theme, busy, invert, fontSizes, fontSizeIndex, inheritSize },
) => css`
  ${!inheritSize && theme.helpers.dynamicFontSize(fontSizes || theme.button[buttonVariant].fontSizes[fontSizeIndex])};
  font-family: ${theme.button[buttonVariant].fontFamily};

  ${!invert &&
    css`
      color: ${theme.button[buttonVariant].color.default};
      background: ${theme.button[buttonVariant].bg.default};
      ${!!theme?.button[buttonVariant].border?.default &&
        `outline: 1px solid ${theme.button[buttonVariant].border.default}`};

      ${(props) => {
        if (props?.noFocusEffect) {
          return css`
            &:not(:disabled):hover {
              ${focusAndHoverEffect(busy, theme, buttonVariant)}
            }
          `;
        }

        return css`
          &:not(:disabled):hover,
          &:not(:disabled):focus {
            ${focusAndHoverEffect(busy, theme, buttonVariant)}
          }
        `;
      }}

      &:not(:disabled):active {
        background: ${!busy && theme.button[buttonVariant].bg.active};
        color: ${theme.button[buttonVariant].color.active};
      }
    `};

  ${invert &&
    css`
      color: ${theme.button[buttonVariant].bg.default};
      background: ${theme.button[buttonVariant].color.default};
      box-shadow: 0 0 0 ${rem(1)} ${theme.button[buttonVariant].bg.default} inset;

      &:hover,
      &:focus {
        .spinner {
          border-color: ${(p) => transparentize(0.25, p.theme.defaultPalette.monochrome[0])};
          border-top-color: ${(p) => transparentize(0.75, p.theme.defaultPalette.monochrome[0])};
        }
      }

      ${(props) => {
        if (props?.noFocusEffect) {
          return css`
            &:not(:disabled):hover {
              ${invertFocusAndHoverEffect(theme, buttonVariant)}
            }
          `;
        }

        return css`
          &:not(:disabled):hover,
          &:not(:disabled):focus {
            ${invertFocusAndHoverEffect(theme, buttonVariant)}
          }
        `;
      }}

      &:not(:disabled):active {
        color: ${theme.button[buttonVariant].bg.active};
        box-shadow: 0 0 0 ${rem(1)} ${theme.button[buttonVariant].bg.active} inset;
      }
    `};
`;

const primaryVariantStyles = (props) => css`
  ${prepareButtonVariantStyles('primary', props)}
`;

const secondaryVariantStyles = (props) => css`
  ${prepareButtonVariantStyles('secondary', props)}
`;

const tertiaryVariantStyles = (props) => css`
  ${prepareButtonVariantStyles('tertiary', props)}

  color: ${props.theme.button.tertiary.color.default};
  background: unset;
  box-shadow: 0 ${rem(-2)} 0 ${rem(-1)} ${props.theme.button.tertiary.color.default} inset;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: ${props.theme.button.tertiary.color.hover};
    box-shadow: 0 ${rem(-2)} 0 ${rem(-1)} ${props.theme.button.tertiary.color.hover} inset;
  }

  &:not(:disabled):active {
    color: ${props.theme.button.tertiary.color.active};
    box-shadow: 0 ${rem(-2)} 0 ${rem(-1)} ${props.theme.button.tertiary.color.active} inset;
  }
`;

const quaternaryVariantStyles = (props) => css`
  ${prepareButtonVariantStyles('quaternary', props)}
`;

const quinaryVariantStyles = (props) => css`
  ${prepareButtonVariantStyles('quinary', props)}
`;

const senaryVariantStyles = (props) => css`
  ${prepareButtonVariantStyles('senary', props)}
  box-shadow: none;
  &:not(:disabled):focus {
    box-shadow: none;
    color: ${props.theme.button.senary.color.active};
    background: ${props.theme.button.senary.bg.active};
  }
`;
const octonaryVariantStyles = (props) => css`
  ${prepareButtonVariantStyles(uiComponentVariant.OCTONARY, props)};
  border: 1px solid ${props.theme.button.octonary.bg.default};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: ${props.theme.button.octonary.color.hover};
    border: 1px solid ${props.theme.button.octonary.bg.active};
  }
`;
const nonaryVariantStyles = (props) => css`
  ${prepareButtonVariantStyles(uiComponentVariant.NONARY, props)};
  border: 1px solid ${props.theme.defaultPalette.monochrome[2]};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: ${props.theme.button.nonary.color.hover};
    border: 1px solid ${props.theme.button.nonary.bg.active};
  }
`;

const septenaryVariantStyles = (props) => css`
  ${prepareButtonVariantStyles(uiComponentVariant.SEPTENARY, props)}
`;

export const Button = styled.button`
  display: ${(p) => (p.block ? 'block' : 'inline-block')};
  width: ${(p) => (p.block ? '100%' : 'auto')};
  font-size: 1em;
  line-height: inherit;
  text-decoration: none;
  border: none;
  background: none;
  text-align: inherit;
  ${({ isRounded }) => (isRounded ? `border-radius: ${em(40)}` : null)};

  ${(p) =>
    !p.blank &&
    css`
      text-align: center;
      padding: 0.5em 1em;
      ${p.theme.helpers.transition(['background', 'box-shadow', 'color'])};

      ${p.variant === uiComponentVariant.PRIMARY && primaryVariantStyles(p)};
      ${p.variant === uiComponentVariant.SECONDARY && secondaryVariantStyles(p)};
      ${p.variant === uiComponentVariant.TERTIARY && tertiaryVariantStyles(p)};
      ${p.variant === uiComponentVariant.QUATERNARY && quaternaryVariantStyles(p)};
      ${p.variant === uiComponentVariant.QUINARY && quinaryVariantStyles(p)};
      ${p.variant === uiComponentVariant.SENARY && senaryVariantStyles(p)};
      ${p.variant === uiComponentVariant.SEPTENARY && septenaryVariantStyles(p)};
      ${p.variant === uiComponentVariant.OCTONARY && octonaryVariantStyles(p)};
      ${p.variant === uiComponentVariant.NONARY && nonaryVariantStyles(p)};
    `};

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    cursor: ${(p) => (p.busy ? 'progress' : 'pointer')};
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: ${(p) => p.theme.button.allVariants.opacity.disabled};
  }

  & > .inner {
    display: flex;
    align-items: center;
    justify-content: center;

    & > .spacer {
      width: 0;
    }

    & > .icon {
      font-size: inherit;
      line-height: inherit;
      margin-right: ${(p) => p.hasContent && p.iconBefore && '0.5em'};
      margin-left: ${(p) => p.hasContent && p.iconAfter && '0.5em'};
    }

    & > .spinner {
      margin-right: ${(p) => p.busyIconBefore && '0.5em'};
      margin-left: ${(p) => p.busyIconAfter && '0.5em'};
    }

    & > .content {
      display: flex;
      align-items: center;
      justify-content: center;

      & > .icon {
        margin: 0 0.5em;
      }
    }
  }
`;
