import styled from 'styled-components';
import { em, transparentize } from 'polished';

const getFontSizeIndex = (theme, index) => {
  const sizesSet = [
    theme.typography.sizesSet.r1,
    theme.typography.sizesSet.r3,
    theme.typography.sizesSet.r14,
    theme.typography.sizesSet.r2,
  ];

  return sizesSet[index];
};
export const Spinner = styled.div`
  border-radius: 100%;
  border-style: solid;

  display: ${(p) => (p.block ? 'block' : 'inline-block')};
  animation: ${(p) => p.theme.animations.rotate360} 1000ms linear infinite;
  ${(p) => p.theme.helpers.transition()};
  border-color: ${(p) =>
    transparentize(0.25, p.inverse ? p.theme.defaultPalette.monochrome[0] : p.theme.defaultPalette.teal[0])};
  border-top-color: ${(p) =>
    transparentize(0.75, p.inverse ? p.theme.defaultPalette.monochrome[0] : p.theme.defaultPalette.teal[0])};
  border-width: ${em(2)};
  height: 1em;
  width: 1em;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;

  ${(p) => p.fontSizeIndex && p.theme.helpers.dynamicFontSize(getFontSizeIndex(p.theme, p.fontSizeIndex))}
`;
