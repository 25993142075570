import * as S from './styles';

const defaultProps = {
  variant: 'primary',
  fontSizes: null,
  fontSizeIndex: 0,
  inheritSize: false,
  component: 'h2',
  fontStyle: 'default',
};

export const Heading = ({
  children,
  variant,
  fontSizes,
  fontSizeIndex,
  inheritSize,
  component,
  fontStyle,
  ...restProps
} = defaultProps) => {
  return (
    <S.Heading
      variant={variant}
      fontSizes={fontSizes}
      fontSizeIndex={fontSizeIndex}
      inheritSize={inheritSize}
      as={component}
      fontStyle={fontStyle}
      {...restProps}
    >
      <span role="presentation">{children}</span>
    </S.Heading>
  );
};

Heading.defaultProps = defaultProps;
