import { forwardRef } from 'react';
import Icon from '../Icon';
import Spinner from '../Spinner';
import * as S from './styles';

const defaultProps = {
  type: 'button',
  variant: 'primary',
  invert: false,
  children: null,
  fontSizes: null,
  fontSizeIndex: 0,
  inheritSize: false,
  disabled: false,
  busy: false,
  inverseBusyIcon: false,
  busyChildren: 'Loading...',
  icon: null,
  iconBefore: true,
  blank: false,
  block: false,
  onClick: () => {},
  isRounded: false,
};

export const Button = forwardRef(
  (
    {
      type,
      variant,
      invert,
      children,
      fontSizes,
      fontSizeIndex,
      inheritSize,
      disabled,
      busy,
      busyChildren,
      inverseBusyIcon,
      icon,
      iconBefore,
      blank,
      block,
      onClick,
      isRounded,
      ...restProps
    } = defaultProps,
    ref,
  ) => {
    const hasIconBefore = icon && iconBefore;
    const hasIconAfter = icon && !iconBefore;
    const hasBusyIconBefore = busy && iconBefore;
    const hasBusyIconAfter = busy && !iconBefore;
    const shouldShowBusyIconOnly = busy && !children;
    const childrenToShow = busy ? busyChildren : children;
    const hasOnlyIconAndNoContent = !children || shouldShowBusyIconOnly;

    return (
      <S.Button
        type={type}
        variant={variant}
        invert={invert}
        fontSizes={fontSizes}
        fontSizeIndex={fontSizeIndex}
        inheritSize={inheritSize}
        disabled={disabled}
        busy={busy}
        iconBefore={hasIconBefore}
        iconAfter={hasIconAfter}
        busyIconBefore={!shouldShowBusyIconOnly && hasBusyIconBefore}
        busyIconAfter={!shouldShowBusyIconOnly && hasBusyIconAfter}
        hasContent={!!children}
        blank={blank}
        block={block}
        onClick={onClick}
        role="button"
        ref={ref}
        isRounded={isRounded}
        {...restProps}
      >
        <span className="inner">
          {!busy && hasIconBefore && <Icon icon={icon} className="icon" />}

          {hasBusyIconBefore && <Spinner className="spinner" busyIconBefore inverse={inverseBusyIcon} />}

          {hasOnlyIconAndNoContent && (
            <span className="spacer" aria-hidden="true">
              &nbsp;
            </span>
          )}

          {shouldShowBusyIconOnly ? null : <span className="content">{childrenToShow}</span>}

          {hasBusyIconAfter && <Spinner busyIconAfter inverse={inverseBusyIcon} className="spinner" />}

          {!busy && hasIconAfter && <Icon icon={icon} className="icon" />}
        </span>
      </S.Button>
    );
  },
);

Button.displayName = 'Button';

Button.defaultProps = defaultProps;
