import * as S from './styles';
import { icons } from '../../styles/icons';
import { multiplePathsIcon } from './multiplePathsIcons';

const defaultProps = {
  fill: 'currentColor',
  block: false,
  svgSupportedViewBox: '0 0 1024 1024',
};

export const Icon = ({ icon, fill, block, svgSupportedViewBox, isMultiplePaths, ...restProps } = defaultProps) => (
  <>
    {isMultiplePaths ? (
      <S.Svg
        viewBox={svgSupportedViewBox}
        width="1em"
        height="1em"
        block={block}
        aria-hidden="true"
        dangerouslySetInnerHTML={{ __html: multiplePathsIcon[icon] }}
        isMultiplePaths={isMultiplePaths}
        {...restProps}
      />
    ) : (
      <S.Svg viewBox={svgSupportedViewBox} width="1em" height="1em" block={block} aria-hidden="true" {...restProps}>
        <S.Path fill={fill} d={icons[icon]} />
      </S.Svg>
    )}
  </>
);

Icon.defaultProps = defaultProps;
