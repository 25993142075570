import * as S from './styles';

const defaultProps = {
  variant: 'primary',
  fontSizes: null,
  fontSizeIndex: 0,
  inheritSize: false,
  component: 'p',
  align: null,
};

export const Text = ({
  children,
  variant,
  fontSizes,
  fontSizeIndex,
  inheritSize,
  component,
  align,
  ...restProps
} = defaultProps) => (
  <S.Text
    variant={variant}
    fontSizes={fontSizes}
    fontSizeIndex={fontSizeIndex}
    inheritSize={inheritSize}
    as={component}
    align={align}
    {...restProps}
  >
    {children}
  </S.Text>
);

Text.defaultProps = defaultProps;
