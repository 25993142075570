import Spacer from 'src/components/Spacer';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import useTimer from 'src/hooks/useTimer';
import useTranslation from 'src/hooks/useTranslation';
import * as S from './styles';

const defaultProps = {
  headingKey: '',
  buttonTextKey: '',
  time: 5,
};

export const CountdownTimer = ({ headingKey, buttonTextKey, handleRedirectToSpecificPage, time }) => {
  const { t } = useTranslation();
  const [counter] = useTimer(handleRedirectToSpecificPage, time);

  return (
    <S.Wrapper>
      {headingKey ? <Text>{t(headingKey)}</Text> : null}

      <Text>
        {t('leftTime')}{' '}
        <Text variant="senary" component="span">
          {counter} {t('seconds')}
        </Text>
      </Text>

      <Spacer />

      {handleRedirectToSpecificPage && !!buttonTextKey ? (
        <Button inverse onClick={handleRedirectToSpecificPage}>
          {buttonTextKey ? t(buttonTextKey) : ''}
        </Button>
      ) : null}
    </S.Wrapper>
  );
};

CountdownTimer.defaultProps = defaultProps;
