export const classNamesOfLayout = {
  MAIN_COLUMN: 'content-main',
  LEFT_COLUMN: 'content-left',
  RIGHT_COLUMN: 'content-right',
  TOP: 'content-top',
  'content-main': 'MAIN_COLUMN',
  'content-left': 'LEFT_COLUMN',
  'content-right': 'RIGHT_COLUMN',
  'content-top': 'TOP',
};
