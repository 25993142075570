export const uiComponentVariant = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  QUATERNARY: 'quaternary',
  QUINARY: 'quinary',
  SENARY: 'senary',
  SEPTENARY: 'septenary',
  OCTONARY: 'octonary',
  NONARY: 'nonary',
  DENARY: 'denary',
  DUODENARY: 'duodenary',
  TRIDENARY: 'tridenary',
  QUATTUORDENARY: 'quattuordenary',
  QUINQUADENARY: 'quinquadenary',
};
