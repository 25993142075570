import styled from 'styled-components';

export const Svg = styled.svg`
  display: ${(props) => (props.block ? 'block' : 'inline')};
  vertical-align: middle;
  shape-rendering: inherit;
  transform: translate3d(0, 0, 0);
  width: 1em;
  height: 1em;
  font-size: 1em;
  flex-shrink: 0;
`;

export const Path = styled.path`
  fill: ${(p) => (p.fill ? p.fill : 'currentColor')};
`;
