import Head from 'next/head';
import { useRouter } from 'next/router';
import { otherLanguages } from 'config/locales';
import CountdownTimer from 'src/components/CountdownTimer';
import Heading from 'src/components/Heading';
import Spacer from 'src/components/Spacer';
import Text from 'src/components/Text';
import * as S from './styles';
import useTranslation from 'src/hooks/useTranslation';

const defaultProps = {
  title: 'pageNotFoundDefaultTitle',
  subtitle: 'pageNotFoundDefaultSubtitle',
  headTitle: 'pageNotFound',
};

export const ErrorPage404 = ({ title, subtitle, headTitle } = defaultProps) => {
  const { t } = useTranslation();
  const { t: tErr } = useTranslation('errors');
  const router = useRouter();

  const redirectTo = () => {
    const [_, localeFromUrl] = router.asPath.split('/');
    const isDefaultLanguage = !otherLanguages.some((item) => item === localeFromUrl);
    if (isDefaultLanguage) {
      const path = '/';
      router.push(path);
      return;
    }

    router.push(`/${localeFromUrl}`);
    return;
  };

  return (
    <main className="main wrapper" role="main">
      <Head>
        <title key="title">{t(headTitle)}</title>
        <meta name="robots" content="noindex,nofollow" />
      </Head>

      <Heading variant="quaternary" component="h1">
        {t('pageNotExist')}
      </Heading>

      <S.Wrapper>
        <Heading variant="senary" fontSizeIndex={1}>
          {tErr(title)}
        </Heading>

        <Text>{tErr(subtitle)}</Text>

        <Spacer large />
        <CountdownTimer
          headingKey={t('pageNotFoundRedirectToHomePage')}
          buttonTextKey={t('pageNotFoundGoToHomePage')}
          handleRedirectToSpecificPage={redirectTo}
        />
      </S.Wrapper>
    </main>
  );
};

ErrorPage404.defaultProps = defaultProps;
