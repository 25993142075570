import styled, { css } from 'styled-components';

const primaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultTextVariant('primary', props)}
  margin: 0 0 1em;
`;

const secondaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultTextVariant('secondary', props)}
`;

const tertiaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultTextVariant('tertiary', props)}
`;

const quaternaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultTextVariant('quaternary', props)}
`;

const quinaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultTextVariant('quinary', props)}
`;

const senaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultTextVariant('senary', props)}
`;

const septenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultTextVariant('septenary', props)}
`;

const octonaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultTextVariant('octonary', props)}
`;

const nonaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultTextVariant('nonary', props)}
`;

const denaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultTextVariant('denary', props)}
`;

const duodenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultTextVariant('duodenary', props)}
`;

const tridenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultTextVariant('tridenary', props)}
`;

const quattuordenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultTextVariant('quattuordenary', props)}
`;
const quinquadenaryVariantStyles = (props) => css`
  ${props.theme.helpers.createDefaultTextVariant('quinquadenary', props)}
`;

export const Text = styled.p`
  font-size: inherit;
  line-height: inherit;
  text-align: ${({ align }) => align};
  ${(p) => p.variant === 'primary' && primaryVariantStyles(p)};
  ${(p) => p.variant === 'secondary' && secondaryVariantStyles(p)};
  ${(p) => p.variant === 'tertiary' && tertiaryVariantStyles(p)};
  ${(p) => p.variant === 'quaternary' && quaternaryVariantStyles(p)};
  ${(p) => p.variant === 'quinary' && quinaryVariantStyles(p)};
  ${(p) => p.variant === 'senary' && senaryVariantStyles(p)};
  ${(p) => p.variant === 'septenary' && septenaryVariantStyles(p)};
  ${(p) => p.variant === 'octonary' && octonaryVariantStyles(p)};
  ${(p) => p.variant === 'nonary' && nonaryVariantStyles(p)};
  ${(p) => p.variant === 'denary' && denaryVariantStyles(p)};
  ${(p) => p.variant === 'duodenary' && duodenaryVariantStyles(p)};
  ${(p) => p.variant === 'tridenary' && tridenaryVariantStyles(p)};
  ${(p) => p.variant === 'quattuordenary' && quattuordenaryVariantStyles(p)};
  ${(p) => p.variant === 'quinquadenary' && quinquadenaryVariantStyles(p)};
`;
